<template>
  <div class="planet" :class="{ hideCon: showInfo }">
    <h5header :name="$t('home.desc1')" :right="false" />
    <div class="planet-con">
      <div class="h5top">
        <div class="left-name flex">
          <div>{{ $t("market.desc45") }}</div>
          <div class="flex" @click="handlerProfit">
            {{ $t("market.desc50")
            }}<img src="../../public/images/land/righticon1.png" alt="" />
          </div>
        </div>
        <p>
          {{ $t("market.desc46")}}<span v-if="profit.lastGetTime != 0">{{common.formatDate(profit.lastGetTime)}}</span><span v-else> --</span>
          <span v-if="profit.lastGetTime != 0">({{ profit.hours }}h) </span>
        </p>
      </div>
      <div class="planet-top animate__animated animate__fadeInUp">
        <div class="top-info flex">
          <div class="top-info-left">
            <div class="left-name flex">
              <div>{{ $t("market.desc45") }}</div>
              <p>
                {{ $t("market.desc46") }}
                <span v-if="profit.lastGetTime != 0">{{
                  common.formatDate(profit.lastGetTime)
                }}</span><span v-else> --</span>
                <span v-if="profit.lastGetTime != 0">({{ profit.hours }}h)
                </span>
              </p>
            </div>
            <div class="left-profit flex">
              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div>{{ $t("market.desc47", { n1: "DU" }) }}</div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.landInComeDu) }}</p>
                <h6 class="speed flex" v-if="profit.landIncomeDuActivity != 0">
                  <img src="../../public/images/new/speedicon.png" alt="" />
                  +{{ common.cutXiaoNum1(profit.landIncomeDuActivity) || 0 }} DU ({{ $t('market.desc206') }})
                </h6>
              </div>

              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div>{{ $t("market.desc47", { n1: "DAOT" }) }}</div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.landInComeDaot) }}</p>
                <h6 class="speed flex" v-if="profit.landInComeDaot != 0">
                  <img src="../../public/images/new/speedicon.png" alt="" />
                  +{{ common.cutXiaoNum1(profit.landInComeDaot) || 0 }} DAOT ({{ $t('market.desc206') }})
                </h6>
              </div>

              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div>{{ $t("market.desc47", { n1: "RC" }) }}</div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.landInComeRc) }}</p>
                <h6 class="speed flex" v-if="profit.landIncomeRcActivity != 0">
                  <img src="../../public/images/new/speedicon.png" alt="" />
                  +{{ common.cutXiaoNum1(profit.landIncomeRcActivity) || 0 }} RC ({{ $t('market.desc206') }})
                </h6>
              </div>

              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div>{{ $t("market.desc48",{ n1: "DU" })}}</div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.landTaxationDu) }}</p>
              </div>

              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div>{{ $t("market.desc48",{ n1: "DAOT" })}}</div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.landTaxationDaot) }}</p>
              </div>

              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div>{{ $t("market.desc49") }}</div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.algebraRewardRc) }}</p>
              </div>
              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div class="flex">{{ $t("market.desc207") }}(DU) <el-tooltip class="box-item" raw-content effect="dark"
                      placement="bottom">
                      <template #content>
                        <div v-html="$t('market.desc208')"></div>
                      </template>

                      <img src="../../public/images/new/tips.png" alt="" class="img1" />
                    </el-tooltip></div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.planetDU) || 0 }}</p>
              </div>
              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div class="flex">{{ $t("market.desc207") }}(RC) <el-tooltip class="box-item" raw-content effect="dark"
                      placement="bottom">
                      <template #content>
                        <div v-html="$t('market.desc208')"></div>
                      </template>
                      <img src="../../public/images/new/tips.png" alt="" class="img1" />
                    </el-tooltip></div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.planetRC) || 0 }}</p>
              </div>
              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div class="flex">{{ $t("market.desc136") }}</div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.recommendDu) }}</p>
              </div>
              <div class="profit-info">
                <div class="name flex">
                  <img src="../../public/images/land/tag.png" alt="" />
                  <div class="flex">
                    {{ $t("assetbag.desc136") }}(DP)
                    <el-tooltip class="box-item" raw-content effect="dark" :content="$t('asset.desc138')"
                      placement="bottom">
                      <img src="../../public/images/new/tips.png" alt="" class="img1" />
                    </el-tooltip>
                  </div>
                </div>
                <p>{{ common.cutXiaoNum1(profit.integralDp) }}</p>
              </div>
            </div>
            <div class="left-btnrevice" @click="handlerRevice" :class="{ nullbtn: totla == 0 }" v-loading="loading1">
              {{ $t("market.desc51") }}
            </div>
          </div>
          <div class="top-info-right">
            <div class="more flex" @click="handlerProfit">
              <div>{{ $t("market.desc50") }}</div>
              <img src="../../public/images/land/righticon1.png" alt="" />
            </div>
            <div class="profit-btn flexcenter" @click="handlerRevice" :class="{ nullbtn: totla == 0 }"
              v-loading="loading1">
              {{ $t("market.desc51") }}
            </div>
          </div>
        </div>
        <div class="activity-wrap flex" v-if="speedInfo.act == 1 || configInfo.act == 1 || landInfo.act == 1">
          <div class="wrap-item flex" v-if="landInfo.act == 1">
            <img src="../../public/images/reward/icon19.png" alt="" class="img1" />
            <div class="item-info">
              <div class="info-name flex">
                {{ $t('reward.desc210') }}
              </div>
              <div class="info-desc">
                {{ $t('reward.desc211') }}
              </div>
            </div>
            <img src="../../public/images/reward/icon18.png" alt="" class="img2" @click="$router.push('/reward/land')" />
          </div>
          <div class="wrap-item flex" v-if="speedInfo.act == 1">
            <img src="../../public/images/reward/icon16.png" alt="" class="img1" />
            <div class="item-info">
              <div class="info-name flex">
                {{ $t('market.desc216') }} <img src="../../public/images/reward/icon17.png" alt="" />
              </div>
              <div class="info-desc">
                {{ $t('market.desc217') }}
              </div>
            </div>
            <img src="../../public/images/reward/icon18.png" alt="" class="img2" @click="$router.push('/reward/speed')" />
          </div>
          <div class="wrap-item flex" v-if="configInfo.act == 1">
            <img src="../../public/images/reward/icon4.png" alt="" class="img1" />
            <div class="item-info">
              <div class="info-name flex">
                {{ $t('market.desc218') }}
              </div>
              <div class="info-desc">
                {{ $t('market.desc220') }}
              </div>
            </div>
            <img src="../../public/images/reward/icon18.png" alt="" class="img2"
              @click="$router.push('/reward/reinvest?act=1')" />
          </div>

        </div>
        <div class="h5top-name flex">
          <div>{{ $t("market.desc124") }}</div>
          <p class="flex" @click="handlerOverview">
            <span v-if="!showOverview">{{ $t("market.desc132") }}</span><span v-else>{{ $t("planet.desc65") }}</span>
            <img src="../../public/images/new/upicon.png" :class="{ img1: showOverview }" />
          </p>
        </div>
        <div class="top-overview flex" v-if="showOverview">
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc52",{n1:'DU'}) }}</div>
            </div>
            <p>{{ overview.investmentTotal || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc52",{n1:'DAOT'}) }}</div>
            </div>
            <p>{{ overview.investmentTotalDaot || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name">

              <div>{{ $t("myincome.desc9") }}</div>
            </div>
            <p>{{ overview.planetCount || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc53") }}</div>
            </div>
            <p>{{ overview.landCount || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc207") }}(DU)</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc208')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.planetDU || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc207") }}(RC)</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc208')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.planetRC || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc54",{n1:'DU'}) }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc61')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.landIncomeDu || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc54",{n1:'DAOT'}) }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc61')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.landIncomeDaot || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc55") }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc62')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.landIncomeRc || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc56") }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc63')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.algebraicGrantsRc || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc57",{n1:'DU'}) }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc64')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.landRevenueDu || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc57",{n1:'DAOT'}) }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc64')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.landRevenueDaot || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc58") }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc65')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.incomeLimitRc || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("assetbag.desc136") }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('assetbag.desc148')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.grantsCredits || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc59",{n1:'DU'}) }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc66')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.lossEarningsDu || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc59",{n1:'DAOT'}) }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc66')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.lossEarningsDaot || 0 }}</p>
          </div>

          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc60") }}</div>
              <el-tooltip effect="dark" placement="bottom">
                <template #content>
                  <div v-html="$t('market.desc67')"></div>
                </template>
                <img src="../../public/images/land/tag1.png" alt="" />
              </el-tooltip>
            </div>
            <p>{{ overview.lossEarningsRc || 0 }}</p>
          </div>
          <div class="overview-info">
            <div class="info-name flex">
              <div>{{ $t("market.desc136") }}</div>
            </div>
            <p>{{ overview.planetRecommendDu || 0 }}</p>
          </div>

        </div>
      </div>
      <div class="h5-search flex animate__animated animate__fadeInUp">
        <div class="user-title">{{ $t("home.desc1") }}</div>
        <div class="user-land flex">
          <div @click="$router.push('/market')">{{ $t("market.desc133") }}</div>
          <img src="../../public/images/land/planetsearch.png" alt="" class="search-icon" @click="showSearch = true" />
        </div>
      </div>
      <div class="planet-title animate__animated animate__fadeInUp">
        {{ $t("market.desc68") }}
      </div>
      <div class="planet-search flex animate__animated animate__fadeInUp">
        <div class="search-item">
          <div>{{ $t("reward.desc221") }}</div>
          <p>
            <el-select v-model="buyType" @change="hanlderSelect">
              <el-option :label="$t('planet.desc40')" :value="''"></el-option>
              <el-option :label="$t('reward.desc222')" :value="'0'"></el-option>
              <el-option :label="$t('reward.desc223')" :value="1"></el-option>
            </el-select>
          </p>
        </div>
        <div class="search-item">
          <div>{{ $t("market.desc3") }}</div>
          <p>
            <el-select v-model="landStatus" @change="hanlderSelect">
              <el-option :label="$t('planet.desc40')" :value="''"></el-option>
              <el-option :label="$t('market.desc75')" :value="'0'"></el-option>
              <el-option :label="$t('market.desc76')" :value="1"></el-option>
              <el-option :label="$t('market.desc77')" :value="2"></el-option>
              <el-option :label="$t('market.desc78')" :value="3"></el-option>
              <!-- <el-option :label="item.desc" :value="item.code" v-for="item in statusList"
                                :key="item.id"></el-option> -->
            </el-select>
          </p>
        </div>
        <div class="search-item">
          <div>{{ $t("market.desc5") }}</div>
          <p>
            <el-select v-model="landLevel" @change="hanlderSelect">
              <el-option :label="$t('planet.desc40')" :value="''"></el-option>

              <el-option :label="item.name" :value="item.id" v-for="item in planetLevel" :key="item.id"></el-option>
            </el-select>
          </p>
        </div>
        <div class="search-item">
          <div>{{ $t("market.desc69") }}</div>
          <p>
            <el-select v-model="investTotalAmountOrder" @change="hanlderSelect">
              <el-option :label="$t('planet.desc40')" :value="''"></el-option>
              <el-option :label="$t('market.desc10')" :value="true"></el-option>

              <el-option :label="$t('market.desc11')" :value="false"></el-option>
            </el-select>
          </p>
        </div>
        <div class="search-item">
          <div>{{ $t("market.desc70") }}</div>
          <p>
            <el-select v-model="buyTimeOrder" @change="hanlderSelect">
              <el-option :label="$t('planet.desc40')" :value="''"></el-option>
              <el-option :label="$t('market.desc71')" :value="true"></el-option>

              <el-option :label="$t('market.desc72')" :value="false"></el-option>
            </el-select>
          </p>
        </div>
      </div>
      <div class="market-list flex animate__animated animate__fadeInUp animate__delay-1s">
        <div class="palnet" v-for="item in list" :key="item.id" :class="[`levelbg${item.levelId}`]">
          <div class="sendtag flex" v-if="item.buyType == 1">
            <img src="../../public/images/reward/sendicon.png" alt="">
            <div>{{ $t('reward.desc224') }}</div>
          </div>
          <div class="speedtag flex" v-if="item.expedite">
            <img src="../../public/images/new/speedicon.png" alt="" />
            <div>{{ $t('market.desc175') }}</div>
          </div>
          <div class="planet-con flex">
            <div class="h5level-name flex">
              L{{ item.levelId }} {{ item.levelName }}
            </div>
            <div class="sales-end" v-if="item.status == 0">
              <p>{{ $t("market.desc75") }}</p>
            </div>
            <div class="sales-end bg1" v-if="item.status == 2">
              <p>{{ $t("market.desc77") }}</p>
            </div>
            <div class="sales-end bg2" v-if="item.status == 3">
              <p>{{ $t("market.desc78") }}</p>
            </div>
            <img :src="item.landIcon" alt="" class="planet-logo" />
            <div class="con-info">
              <div class="con-name">
                {{ $t("market.desc73", { n1: item.planetName }) }}
              </div>
              <div class="con-level flex">
                <div class="level-name flex">
                  L{{ item.levelId }} {{ item.levelName }}
                </div>
                <div class="level-tag">
                  {{
                    $t("market.desc74", {
                      n1: common.formatDate1(item.effectiveTime),
                    })
                  }}
                </div>
              </div>
              <div class="teamtag flex">

                <div class="tagrate flex" @click="handlerRate">
                  <div>{{ $t('market.desc176', { n1: common.getnum(item.rate * 100) }) }}</div>
                  <img src="../../public/images/new/rateicon.png" alt="" />
                </div>
                <div class="sendtag1 flex">
                  <img src="../../public/images/reward/sendicon.png" alt="">
                  <div>{{ $t('reward.desc224') }}</div>
                </div>
              </div>
              <div class="info-list flex">
                <div class="list">
                  <div>{{ $t("market.desc12") }}</div>
                  <p>{{ item.planetCoordinate }}</p>
                </div>
                <div class="list">
                  <div>{{ $t("market.desc3") }}</div>
                  <p v-if="item.status == 0">{{ $t("market.desc75") }}</p>
                  <p v-if="item.status == 1">{{ $t("market.desc76") }}</p>
                  <p class="flex" v-if="item.status == 2">
                    {{ $t("market.desc77") }}
                    <el-tooltip effect="dark" placement="bottom">
                      <template #content>
                        <div v-html="$t('market.desc117')"></div>
                      </template>
                      <img src="../../public/images/land/tag2.png" alt="" />
                    </el-tooltip>
                  </p>
                  <p class="flex" v-if="item.status == 3">
                    {{ $t("market.desc78")
                    }}<el-tooltip effect="dark" placement="bottom">
                      <template #content>
                        <div v-html="$t('market.desc116')"></div>
                      </template>
                      <img src="../../public/images/land/tag2.png" alt="" />
                    </el-tooltip>
                  </p>
                </div>
                <div class="list">
                  <div>{{ $t("market.desc79") }}</div>
                  <p>{{ item.earningsDays }}</p>
                </div>
                <div class="list">
                  <div>{{ $t("market.desc80") }}</div>
                  <p>{{ item.investTotalAmount }} {{item.earningsCoinName}}</p>
                </div>
              </div>
              <div class="info-btn flex">
                <!-- <div @click="handlerDive(item.id)" v-if="item.status != 3 && item.levelId == 1">
                  {{ $t("market.desc81") }}
                </div>
                <div @click="handlerAdd(item.id, item.investTotalAmount)" v-if="(item.status == 1 && item.append) ||
                  (item.status == 0 && item.append)
                  ">
                  {{ $t("market.desc82") }}
                </div> -->
                <div @click="handlerDetail(item)">
                  {{ $t("market.desc83") }}
                </div>
              </div>
            </div>
          </div>
          <div class="samllinfo-btn flex">
            <div @click="handlerDive(item.id)" v-if="item.status != 3 && item.levelId == 1">
              {{ $t("market.desc81") }}
            </div>
            <div @click="handlerAdd(item.id, item.investTotalAmount)" v-if="(item.status == 1 && item.append) ||
              (item.status == 0 && item.append)
              ">
              {{ $t("market.desc82") }}
            </div>
            <div @click="handlerDetail(item)">{{ $t("market.desc83") }}</div>
          </div>
        </div>
      </div>
      <div class="planet-page animate__animated animate__fadeInUp animate__delay-1s">
        <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page" :total="totals"
          :hide-on-single-page="true" @current-change="handlerCurrent">
        </el-pagination>
      </div>
      <div class="no-data" v-if="showNull">
        <img src="../../public/images/no-data.png" alt="" />
        <span>{{ $t("record.desc13") }}</span>
      </div>

      <PageLoading v-model:show="showLoading" />
    </div>
    <!-- 撤资 -->
    <el-dialog v-model="ShowDivestment" :append-to-body="true" class="bigdialog">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="ShowDivestment = false" />
        <div class="modal-title">{{ $t("market.desc84") }}</div>
        <div class="modal-desc" v-if="divestmentInfo.userName != ''">
          {{ $t("market.desc85", { n1: divestmentInfo.userName }) }}
        </div>
        <div class="modal-desc" v-else>
          {{ $t("market.desc85", { n1: $t("home.desc61") }) }}
        </div>
        <div class="modal-info">
          <div class="modal-info-list flex">
            <div>{{ $t("market.desc86") }}</div>
            <p>{{ divestmentInfo.track }} - {{ divestmentInfo.no }}</p>
          </div>
          <div class="modal-info-list flex">
            <div>{{ $t("market.desc87") }}</div>
            <p>
              {{ divestmentInfo.disinvestAmount }}
              {{ divestmentInfo.coinIdName }}
            </p>
          </div>
          <div class="modal-info-list flex">
            <div>
              {{
                $t("market.desc88", {
                  n1: parseFloat(divestmentInfo.disinvestFeeRate) * 100,
                })
              }}
            </div>
            <p>
              {{ divestmentInfo.disinvestFee }} {{ divestmentInfo.coinIdName }}
            </p>
          </div>
          <div class="modal-info-list flex">
            <div>{{ $t("market.desc89") }}</div>
            <p>
              {{ divestmentInfo.arriveAmount }} {{ divestmentInfo.coinIdName }}
            </p>
          </div>
        </div>
        <div class="modal-tipsname flex">
          <img src="../../public/images/land/tag3.png" alt="" />{{
            $t("market.desc95")
          }}
        </div>
        <div class="modal-tips">
          <div>{{ $t("market.desc90") }}</div>
          <div>
            {{
              $t("market.desc91", {
                n1: parseFloat(divestmentInfo.disinvestFeeRate) * 100,
              })
            }}
          </div>
          <div>{{ $t("market.desc92") }}</div>
        </div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="ShowDivestment = false">
            {{ $t("market.desc93") }}
          </div>
          <div class="flexcenter" v-loading="loading" @click="handlerDiveBtn">
            {{ $t("market.desc94") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 追加 -->
    <el-dialog v-model="ShowAdd" :append-to-body="true" class="bigdialog">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="ShowAdd = false" />
        <div class="modal-title" v-if="landaddInfo.userName != ''">
          {{
            $t("market.desc97", {
              n1: landaddInfo.userName,
            })
          }}
        </div>
        <div class="modal-title" v-else>
          {{ $t("market.desc97", { n1: $t("home.desc61") }) }}
        </div>
        <div class="modal-addinfo">
          <div class="modal-info-list flex">
            <div>{{ $t("market.desc98") }}</div>
            <p>{{ landaddInfo.track }} - {{ landaddInfo.no }}</p>
          </div>
          <div class="modal-info-list flex">
            <div>{{ $t("market.desc99") }}</div>
            <p>{{ investmentAmount }} {{item.earningsCoinName}}</p>
          </div>
        </div>
        <div class="modal-typename">{{ $t("market.desc24") }}</div>
        <div class="modal-type-tab flex">
          <div class="flexcenter" :class="{ ontab: accountType == 1 }" @click="handlerTab(1)">
            {{ $t("node.desc27") }}
          </div>
          <div class="flexcenter" :class="{ ontab: accountType == 2 }" @click="handlerTab(2)">
            {{ $t("node.desc28") }}
          </div>
        </div>
        <div class="modal-typename">{{ $t("node.desc26") }}</div>
        <div class="modal-select">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <div class="icon-name flex" v-if="payType == 2">
                <div class="inputimg">
                  <img :src="item.landPayCoinIcon" alt="" v-for="item in landaddInfo.landPayCoinItemList" />
                </div>
                <div class="inputname flex">
                  <p v-for="(item, i) in landaddInfo.landPayCoinItemList">
                    {{ item.landPayCoinName }}<span v-if="i == 0">+</span>
                  </p>
                </div>
              </div>
              <div class="payinfo flex" v-else v-for="(item, i) in landaddInfo.landPayCoinItemList" :key="item.id"
                v-show="i == 0">
                <img :src="item.landPayCoinIcon" alt="" />
                {{ item.landPayCoinName }}
              </div>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :command="1">
                  <div class="payinfo1 flex" v-for="(item, i) in landaddInfo.landPayCoinItemList" :key="item.id"
                    v-show="item.landPayCoinName == 'DU'">
                    <img :src="item.landPayCoinIcon" alt="" />
                    {{ item.landPayCoinName }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item :command="2" v-if="paytypeLen > 1">
                  <div class="payinfo1 flex">
                    <div class="inputimg">
                      <img :src="item.landPayCoinIcon" alt="" v-for="item in landaddInfo.landPayCoinItemList" />
                    </div>
                    <div class="inputname flex">
                      <p v-for="(item, i) in landaddInfo.landPayCoinItemList">
                        {{ item.landPayCoinName }}<span v-if="i == 0">+</span>
                      </p>
                    </div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="modal-typetips" v-if="payType == 2">
          {{ $t("market.desc40") }} <span>{{ coinTwoRate }}%</span>
        </div>
        <div class="modal-typename" v-if="accountType == 1">
          {{ $t("market.desc25") }}
        </div>
        <div class="modal-typename" v-else>{{ $t("market.desc43") }}</div>
        <div class="modal-balance" v-if="payType == 1">
          <div class="payinfo flex" v-for="item in landaddInfo.balanceItemList" :key="item.id"
            v-show="item.landPayCoinName == 'DU'">
            {{ item.amount }} {{ item.landPayCoinName }}
          </div>
        </div>
        <div class="modal-balance" v-if="payType == 2">
          <div class="payamount flex" v-for="(item, i) in landaddInfo.balanceItemList" :key="item.id">
            <p class="flex">
              <img :src="landaddInfo.landPayCoinItemList[i].landPayCoinIcon" />{{ item.landPayCoinName }}
            </p>
            <div>{{ item.amount }}</div>
          </div>
        </div>
        <div class="modal-typename">{{ $t("market.desc100") }}</div>
        <div class="modal-input flex">
          <input type="tel" oninput="value=value.replace(/[^\d]/g,'')" :placeholder="$t('market.desc104', {
            n1: landaddInfo.minPrice,
            n2: landaddInfo.maxPrice,
          })
            " v-model="amount" @change="onChange" />
          <p>DU</p>
          <div @click="handlerAll">{{ $t("market.desc101") }}</div>
        </div>
        <div class="modal-tips">
          <div>{{ $t("market.desc174") }}</div>
          <div>{{ $t("market.desc172") }}</div>
          <div>{{ $t("market.desc173") }}</div>
        </div>
        <div class="paylist" v-if="payType == 2 && payInfo != ''">
          <div class="list-info flex" v-for="item in payInfo.needPayItemList" :key="item.coinId">
            <div>{{ item.landPayCoinName }}{{ $t("market.desc41") }}</div>
            <p>{{ item.amount }} {{ item.landPayCoinName }}</p>
          </div>
        </div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="ShowAdd = false">
            {{ $t("home.desc45") }}
          </div>
          <div class="flexcenter" @click="handlerAddBtn">
            {{ $t("market.desc103") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 密码 -->
    <el-dialog v-model="showpsd" :append-to-body="true">
      <div class="modal-con1">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showpsd = false" />
        <div class="modal-title">{{ $t("home.desc7") }}</div>
        <div class="modal-desc">{{ $t("node.desc53") }}</div>
        <div class="modal-input">
          <psdinput @finish="finish" @finish1="finish1" ref="PsdInput" v-if="showpsd" />
        </div>

        <div class="modal-btncon flexcenter" @click="handlerPay" v-loading="loading">
          {{ $t("home.desc20") }}
        </div>
        <div class="forgottitle" @click="handlerLogpsd">{{ $t('sign.desc72') }}</div>
      </div>
    </el-dialog>
    <marketInfo :planetId="planetId" :levelId="levelId" :landId="landId" @handlerClose="handlerClose" :type="2" v-if="showInfo" />
    <el-dialog v-model="showSuccess" :append-to-body="true">
      <div class="modal-con1">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showSuccess = false" />
        <div class="sucessicon">
          <img src="../../public/images/new/success.png" alt="" />
        </div>
        <div class="success-title">{{ $t("market.desc128") }}</div>
        <div class="success-tips" v-html="$t('market.desc129')"></div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showSuccess = false">
            {{ $t("market.desc130") }}
          </div>
          <div class="flexcenter" @click="handlerProfit">
            {{ $t("market.desc131") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="showTips" :append-to-body="true">
      <div class="modal-con1">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showTips = false" />
        <div class="modal-title">{{ $t("asset.desc77") }}</div>
        <div class="modal-desc">{{ $t("market.desc144") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showTips = false">
            {{ $t("home.desc45") }}
          </div>
          <div class="flexcenter" @click="handlerNext">
            {{ $t("market.desc145") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 筛选 -->
    <van-popup v-model:show="showSearch" position="bottom" round>
      <div class="search-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showSearch = false" />
        <div class="search-title">{{ $t("market.desc122") }}</div>
        <div class="search-list">
          <div class="list-item">
            <div class="list-name">{{ $t("reward.desc221") }}</div>
            <div class="list-info flex">
              <div class="flexcenter" :class="{ on: buyType1 == 99 }" @click="handlerBuyType(99)">
                {{ $t("planet.desc40") }}
              </div>
              <div class="flexcenter" :class="{ on: buyType1 == 0 }" @click="handlerBuyType(0)">
                {{ $t("reward.desc222") }}
              </div>
              <div class="flexcenter" :class="{ on: buyType1 == 1 }" @click="handlerBuyType(1)">
                {{ $t("reward.desc223") }}
              </div>

            </div>
          </div>
          <div class="list-item">
            <div class="list-name">{{ $t("market.desc3") }}</div>
            <div class="list-info flex">
              <div class="flexcenter" :class="{ on: landStatus1 == 99 }" @click="handlerPlant(99)">
                {{ $t("planet.desc40") }}
              </div>
              <div class="flexcenter" :class="{ on: landStatus1 == 0 }" @click="handlerPlant(0)">
                {{ $t("market.desc75") }}
              </div>
              <div class="flexcenter" :class="{ on: landStatus1 == 1 }" @click="handlerPlant(1)">
                {{ $t("market.desc76") }}
              </div>
              <div class="flexcenter" :class="{ on: landStatus1 == 2 }" @click="handlerPlant(2)">
                {{ $t("market.desc77") }}
              </div>
              <div class="flexcenter" :class="{ on: landStatus1 == 3 }" @click="handlerPlant(3)">
                {{ $t("market.desc78") }}
              </div>
            </div>
          </div>
          <div class="list-item">
            <div class="list-name">{{ $t("market.desc5") }}</div>
            <div class="list-info flex">
              <div class="flexcenter" :class="{ on: landLevel == '' }" @click="handlerPlantType('')">
                {{ $t("planet.desc40") }}
              </div>
              <div class="flexcenter" :class="{ on: landLevel == item.id }" :key="item.id" v-for="item in planetLevel"
                @click="handlerPlantType(item.id)">
                {{ item.name }}
              </div>
            </div>
          </div>

          <div class="list-item">
            <div class="list-name">{{ $t("market.desc69") }}</div>
            <div class="list-info flex">
              <div class="flexcenter" :class="{ on: investTotalAmountOrder == 99 }" @click="investTotalAmountOrder = 99">
                {{ $t("planet.desc40") }}
              </div>
              <div class="flexcenter" :class="{ on: investTotalAmountOrder == true }"
                @click="investTotalAmountOrder = true">
                {{ $t("market.desc10") }}
              </div>
              <div class="flexcenter" :class="{ on: investTotalAmountOrder == false }"
                @click="investTotalAmountOrder = false">
                {{ $t("market.desc11") }}
              </div>
            </div>
          </div>
          <div class="list-item">
            <div class="list-name">{{ $t("market.desc70") }}</div>
            <div class="list-info flex">
              <div class="flexcenter" :class="{ on: buyTimeOrder == 99 }" @click="buyTimeOrder = 99">
                {{ $t("planet.desc40") }}
              </div>
              <div class="flexcenter" :class="{ on: buyTimeOrder == true }" @click="buyTimeOrder = true">
                {{ $t("market.desc10") }}
              </div>
              <div class="flexcenter" :class="{ on: buyTimeOrder == false }" @click="buyTimeOrder = false">
                {{ $t("market.desc11") }}
              </div>
            </div>
          </div>
        </div>
        <div class="search-btn flex">
          <div class="flexcenter" @click="handlerReset">
            {{ $t("market.desc123") }}
          </div>
          <div class="flexcenter" @click="handlerSearch">
            {{ $t("asset.desc59") }}
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 比例说明 -->
    <van-popup v-model:show="showRate" :close-on-click-overlay="false">
      <div class="modal-con ratecon">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showRate = false" />
        <div class="modal-title">{{ $t("market.desc177") }}</div>
        <div class="rate-list">
          <div>{{ $t('market.desc178') }}</div>
          <div>{{ $t('market.desc179') }}</div>
        </div>
        <div class="rate-table">
          <div class="table-thead flex">
            <div class="flexcenter">{{ $t('market.desc180') }}</div>
            <div class="flexcenter">{{ $t('market.desc181') }}</div>
            <div class="flexcenter">{{ $t('market.desc182') }}</div>
            <div class="flexcenter">{{ $t('market.desc183') }}</div>
          </div>
          <div class="table-tbody">
            <div class="tbody-info flex" v-for="item in rateList" :key="item.id">
              <div>{{ item.planetLandSellRatio * 100 }} %</div>
              <div>{{ item.landReleaseRatio * 100 }} %</div>
              <div>{{ $t('pool.desc13', { n1: item.releaseDay }) }} </div>
              <div>{{ item.planetReleaseRatio * 100 }} %</div>
            </div>
          </div>
        </div>
        <div class="ratebtn flexcenter" @click="showRate = false">{{ $t('assetbag.desc71') }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import psdinput from "@/componets/psdinput.vue";
import marketInfo from "@/componets/marketInfo.vue";
import h5header from "@/componets/h5header.vue";
import bus from "@/utils/bus";
export default {
  components: {
    psdinput,
    marketInfo,
    h5header,
  },
  data() {
    return {
      showTips: false,
      showSearch: false,
      showSuccess: false,
      showpsd: false,
      ShowAdd: false,
      ShowDivestment: false,
      profit: {},
      overview: {},
      landStatus: "",
      landLevel: "",
      planetLevel: [],
      investTotalAmountOrder: "",
      buyTimeOrder: "",
      page: 1,
      totals: 0,
      list: [],
      statusList: [],
      showNull: false,
      divestmentInfo: {},
      loading: false,
      landId: "",
      landaddInfo: {},
      accountType: 1,
      payType: 1,
      paytypeLen: 1,
      investmentAmount: 0,
      amount: "",
      payInfo: {},
      password: "",
      userInfo: {},
      showInfo: false,
      levelId: "",
      planetId: "",
      loading1: false,
      showOverview: false,
      landStatus1: 99,
      totla: 0,
      coinTwoRate: 0,
      showLoading: true,
      rateList: [],
      showRate: false,
      speedInfo: {},
      landInfo: {},
      configInfo: {},
      buyType: '',
      buyType1: 99
    };
  },
  mounted() {
    this.handleInit();
    // 比例说明
    this.$post(this.URL.planet.config, {}).then(res => {
      if (res.code == 0) {
        this.rateList = res.data;
      }
    })
    this.$post(this.URL.assets.rechargeConfig, {
      activityType: 3
    }).then(res => {
      if (res.code == 0 && res.data != null) {

        let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();

        if (todayTime > res.data.endTime) {
          res.data.act = 2;
        } else if (todayTime < res.data.startTime) {
          res.data.act = 0;
          res.data.time = res.data.startTime - todayTime;
        } else {
          res.data.time = res.data.endTime - todayTime
          res.data.act = 1;
        }

        this.speedInfo = res.data;
      }
    })
    this.$post(this.URL.reward.land, {
      activityType: 2
    }).then(res => {
      if (res.code == 0) {
        let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime();
        res.data.startTime = res.data.startTime - time1;
        res.data.endTime = res.data.endTime - time1;
        res.data.datetime = this.common.formatDate(res.data.endTime)
        if (todayTime > res.data.endTime + time1) {
          res.data.act = 2;
        } else if (todayTime < res.data.startTime + time1) {
          res.data.act = 0;
        } else {
          res.data.time = res.data.endTime + time1 - todayTime
          res.data.act = 1;
        }
        if (res.data.awardAccountType == 1) {
          res.data.awardAccountTypeName = this.$t('new.desc7')
        } else if (res.data.awardAccountType == 2) {
          res.data.awardAccountTypeName = this.$t('new.desc8')
        } else {
          res.data.awardAccountTypeName = this.$t('new.desc9')
        }
        this.configInfo = res.data;
      }
    })
    //土地购买赠送
    this.$post(this.URL.reward.planetConfig, {
      type: 2
    }).then(res => {
      if (res.code == 0) {
        let time1 = 4 * 60 * 60 * 1000, todayTime = new Date().getTime(), startTime = new Date(res.data.startTime).getTime() + time1, endTime = new Date(res.data.endTime).getTime() + time1;

        if (todayTime > endTime) {
          res.data.act = 2;
        } else if (todayTime < startTime) {
          res.data.act = 0;
          res.data.time = startTime - todayTime;
        } else {
          res.data.time = endTime - todayTime
          res.data.act = 1;
        }
        this.landInfo = res.data;
      }
    })
  },
  methods: {
    handlerRate() {
      if (localStorage.getItem('ismobile') == 1) {
        this.$router.push('/market/rules');
        return;
      }
      this.showRate = true
    },
    async handleInit() {
      try {
        await this.getrevice();
        const planetProfit = await this.$post(this.URL.market.planetProfit, {});
        if (planetProfit.code == 0) {
          this.overview = planetProfit.data;
        }
        const planetLevel = await this.$post(this.URL.market.landLevel, {});
        if (planetLevel.code == 0) {
          this.planetLevel = planetLevel.data;
        }
        const landStatus = await this.$post(this.URL.market.landStatus, {});
        if (landStatus.code == 0) {
          this.statusList = landStatus.data;
        }
        await this.getlist();
        if (localStorage.getItem("token") != null) {
          const info = await this.$post(this.URL.member.info, {});
          if (info.code == 0) {
            this.userInfo = info.data;
          }
        }
        this.showLoading = false;
      } catch (error) {
        this.showLoading = false;
      }
      bus.on("hadlerSuccess", (e) => {
        this.showSuccess = true;
      });
    },
    handlerNext() {
      this.showTips = false;
      this.loading = false;
      this.$post(this.URL.market.disinvest, { landId: this.landId }).then(
        (res) => {
          if (res.code == 0) {
            this.divestmentInfo = res.data;
            this.ShowDivestment = true;
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    handlerLogpsd() {
      this.showpsd = false;
      this.ShowAdd = false;
      if (localStorage.getItem('ismobile') == 1) {
        this.$router.push('/user/info?id=2')
      } else {
        bus.emit("hadleropen", 1);
      }
    },
    gettime() {
      return new Date(
        new Date().getTime() +
        (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000
      );
    },
    getInervalHour(startDate, endDate) {
      var ms = endDate.getTime() - startDate * 1000;
      if (ms < 0) return 0;
      return Math.floor(ms / 1000 / 60 / 60);
    },
    handlerProfit() {
      // this.$store.commit("SET_ASSETTYPE", 3);
      this.$store.commit("SET_ASSETTYPE", 5);
      this.$router.push("/asset");
    },
    handlerPlant(val) {
      this.landStatus1 = val;
      if (val == 99) {
        this.landStatus = "";
      } else {
        this.landStatus = val;
      }
    },
    handlerBuyType(val) {
      this.buyType1 = val;
    },
    handlerPlantType(val) {
      this.landLevel = val;
    },
    handlerSearch() {
      this.list = [];
      this.page = 1;
      this.totals = 0;
      this.showNull = false;
      this.showSearch = false;
      if (this.buyType1 == 99) {
        this.buyType1 = '';
      }
      this.buyType = this.buyType1;
      this.getlist();
    },
    handlerReset() {
      this.landStatus = "";
      this.landStatus1 = 99;
      this.landLevel = "";
      this.buyType1 = 99;
      this.investTotalAmountOrder = "";
      this.buyTimeOrder = "";
    },
    handlerOverview() {
      this.showOverview
        ? (this.showOverview = false)
        : (this.showOverview = true);
    },
    handlerRevice() {
      let _this = this;
      let totla =
        parseFloat(this.profit.landInComeDu) +
        parseFloat(this.profit.landInComeRc) +
        parseFloat(this.profit.landTaxationDu) +
        parseFloat(this.profit.algebraRewardRc) +
        parseFloat(this.profit.recommendDu) +
        parseFloat(this.profit.landInComeDaot) +
        parseFloat(this.profit.landTaxationDaot) +
        parseFloat(this.profit.integralDp);

      if (totla == 0) {
        return;
      }
      if (this.loading1) {
        return;
      }
      this.loading1 = true;

      this.$post(this.URL.market.withdraw, {}).then((res) => {
        this.loading1 = false;
        if (res.code == 0) {
          //this.showSuccess = true;
          this.getrevice();
          setTimeout(() => {
            _this.$store.dispatch("togetherReceiveAfter");
          }, 1000);
        } else {
          this.loading1 = false;
          this.$message.error(res.message);
        }
      });
    },
    async getrevice() {
      const res = await this.$post(this.URL.market.profitlist, {});
      if (res.code == 0) {
        this.profit = res.data;
        this.totla =
          parseFloat(this.profit.landInComeDu) +
          parseFloat(this.profit.landInComeRc) +
          parseFloat(this.profit.landTaxationDu) +
          parseFloat(this.profit.algebraRewardRc) +
          parseFloat(this.profit.recommendDu) +
          parseFloat(this.profit.integralDp);

        this.profit.hours = this.getInervalHour(
          this.profit.lastGetTime,
          this.gettime()
        );
      }
    },
    handlerDetail(info) {
      this.levelId = info.levelId;
      this.planetId = info.planetId;
      this.landId = info.id;
      this.showInfo = true;
    },
    handlerClose(val) {
      this.showInfo = false;
    },
    handlerPay() {
      if (this.password == "") {
        this.$message.error(this.$t("planet.desc17"));
        return;
      }
      let payItemJsonArray = [];
      if (this.payType == 1) {
        payItemJsonArray = [
          {
            amount: this.amount,
            coinId: this.landaddInfo.landPayCoinItemList[0].landPayCoinId,
          },
        ];
      } else {
        this.payInfo.needPayItemList.forEach((e) => {
          payItemJsonArray.push({
            amount: e.amount,
            coinId: e.coinId,
          });
        });
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$post(this.URL.market.landAdd, {
        landId: this.landId,
        accountType: this.accountType,
        payType: this.payType,
        password:
          this.userInfo.sourceType == 2 || this.userInfo.sourceType == 3
            ? this.password
            : this.common.mdpassword(this.password),
        amount: this.amount,
        payItemJsonArray: JSON.stringify(payItemJsonArray),
      }).then((res) => {

        if (res.code == 0) {
          this.showpsd = false;
          this.ShowAdd = false;
          this.$message.success(this.$t("market.desc106"));
          this.getlist();
        } else {
          this.loading = false;
          this.password = "";
          this.$refs.PsdInput.reset();
          this.$message.error(res.message);
        }
      });
    },
    finish(val) {
      this.password = val;
    },
    finish1(val) {
      if (val == 0) {
        this.password = "";
      }
    },
    handlerAdd(id, amount) {
      this.landId = id;
      this.investmentAmount = amount;
      this.accountType = 1;
      this.payType = 1;
      this.loading = false;
      this.amount = "";
      this.$post(this.URL.market.landAddInfo, {
        landId: id,
        accountType: this.accountType,
        payType: this.payType,
      }).then((res) => {
        if (res.code == 0) {
          this.landaddInfo = res.data;
          if (this.landaddInfo.landPayCoinItemList.length > 1) {
            let coinTwoRate = parseFloat(this.landaddInfo.coinTwoRate) * 100;
            this.coinTwoRate = coinTwoRate.toFixed(0);
          }
          this.paytypeLen = res.data.landPayCoinItemList.length;
          this.ShowAdd = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlerTab(i) {
      this.accountType = i;
      this.payType = 1;
      this.amount = "";
      this.$post(this.URL.market.landAddInfo, {
        landId: this.landId,
        accountType: this.accountType,
        payType: this.payType,
      }).then((res) => {
        if (res.code == 0) {
          this.landaddInfo = res.data;
          this.paytypeLen = res.data.landPayCoinItemList.length;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlerAll() {
      this.amount = this.landaddInfo.maxPrice;
      if (this.payType == 2) {
        this.$post(this.URL.market.paycal, {
          planetId: this.landaddInfo.planetId,
          landId: this.landaddInfo.planetLandId,
          payAmount: this.amount,
          type: 2,
        }).then((res) => {
          if (res.code == 0) {
            this.payInfo = res.data;
          }
        });
      }
    },
    onChange(val) {
      if (this.amount == "") {
        return;
      }
      if (this.amount < parseFloat(this.landaddInfo.minPrice)) {
        this.$message.error(
          this.$t("market.desc34", { n1: this.landaddInfo.minPrice })
        );
        this.amount = parseFloat(this.landaddInfo.minPrice);
        this.payInfo = {};
      } else if (this.amount > parseFloat(this.landaddInfo.maxPrice)) {
        this.$message.error(
          this.$t("market.desc35", { n1: this.landaddInfo.maxPrice })
        );
        this.amount = parseFloat(this.landaddInfo.maxPrice);
        this.payInfo = {};
      } else if (this.payType == 2) {
        this.$post(this.URL.market.paycal, {
          planetId: this.landaddInfo.planetId,
          landId: this.landaddInfo.planetLandId,
          payAmount: this.amount,
          type: 2,
        }).then((res) => {
          if (res.code == 0) {
            this.payInfo = res.data;
          }
        });
      }
    },
    handlerAddBtn() {
      if (this.amount == "") {
        this.$message.error(this.$t("market.desc105"));
        return;
      }
      if (this.amount < parseFloat(this.landaddInfo.minPrice)) {
        this.$message.error(
          this.$t("market.desc34", { n1: this.landaddInfo.minPrice })
        );
        return;
      }
      if (this.amount > parseFloat(this.landaddInfo.maxPrice)) {
        this.$message.error(
          this.$t("market.desc35", { n1: this.landaddInfo.maxPrice })
        );
        return;
      }
      if (!this.landaddInfo.isEnoughBalance) {
        this.$message.error(this.$t("market.desc28"));
        return;
      }
      this.showpsd = true;
    },
    handleCommand(val) {
      if (val == this.payType) {
        return;
      }
      this.amount = "";
      this.payType = val;
      this.$post(this.URL.market.landAddInfo, {
        landId: this.landId,
        accountType: this.accountType,
        payType: this.payType,
      }).then((res) => {
        if (res.code == 0) {
          this.landaddInfo = res.data;
          this.paytypeLen = res.data.landPayCoinItemList.length;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlerDive(id) {
      this.landId = id;
      this.showTips = true;
    },
    handlerDiveBtn() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$post(this.URL.market.disinvestBtn, { landId: this.landId }).then(
        (res) => {

          if (res.code == 0) {
            this.$message.success(this.$t("market.desc96"));
            this.ShowDivestment = false;
            this.getlist();
          } else {
            this.loading = false;
            this.$message.error(res.message);
          }
        }
      );
    },
    hanlderSelect(val) {
      this.list = [];
      this.page = 1;
      this.totals = 0;
      this.showNull = false;
      this.getlist();
    },
    async getlist() {
      let investTotalAmountOrder = this.investTotalAmountOrder,
        buyTimeOrder = this.buyTimeOrder;
      if (this.investTotalAmountOrder == 99) {
        investTotalAmountOrder = "";
      }
      if (this.buyTimeOrder == 99) {
        buyTimeOrder = "";
      }
      const res = await this.$post(this.URL.market.landlist, {
        page: this.page,
        pageSize: 10,
        landLevel: this.landLevel,
        landStatus: this.landStatus,
        investTotalAmountOrder: investTotalAmountOrder,
        buyTimeOrder: buyTimeOrder,
        buyType: this.buyType
      });
      if (res.code == 0) {
        this.list = res.data.list;
        this.totals = parseFloat(res.data.page.count);
        if (this.list.length == 0) {
          this.showNull = true;
        }
      }
    },
    handlerCurrent(val) {
      this.page = val;
      this.getlist();
    },
  },
};
</script>

<style lang="less" scoped>
.h5-search {
  display: none;
}

.planet {
  position: relative;
  padding: 136px 0 80px;

  .sendtag {
    position: absolute;
    top: 12px;
    right: 7px;
    padding: 4px 8px;
    background: rgba(254, 180, 1, 0.08);
    border-radius: 4px;

    img {
      flex: 0 0 14px;
      width: 14px;
      height: 12px;
      margin-right: 4px;
    }

    div {
      font-size: 12px;
      line-height: 12px;
      color: #FEB401;
    }
  }

  .speedtag {
    position: absolute;
    top: 25px;
    left: 20px;
    min-width: 104px;
    height: 26px;
    border-radius: 8px;
    z-index: 56;
    background: linear-gradient(180deg, rgba(160, 246, 75, 0.60) -10.71%, rgba(50, 100, 20, 0.60) 84.58%);

    img {
      flex: 0 0 30px;
      width: 30px;
      height: 31px;
      margin: -5px 0 0 -16px;
    }

    div {
      flex: 1;
      padding-left: 6px;
      font-size: 14px;
      color: #C9FA5B;
      line-height: 24px;
    }
  }

  .planet-con {
    width: 1240px;
    margin: 0 auto;

    .planet-top {
      .top-info {
        padding: 24px 32px;
        border-radius: 12px;
        background: #181818;

        .top-info-left {
          flex: 1;

          .left-name {
            margin-bottom: 20px;
            font-size: 24px;
            color: #ffffff;
            line-height: 24px;

            p {
              margin: 10px 0 0 12px;
              font-size: 14px;
              line-height: 14px;
              color: rgba(255, 255, 255, 0.6);
            }
          }

          .left-profit {
            flex-wrap: wrap;

            .profit-info {
              flex: 0 0 25%;

              &:nth-child(-n + 8) {
                margin-bottom: 24px;
              }

              .name {
                font-size: 14px;
                color: #ffffff;
                line-height: 14px;

                img {
                  width: 12px;
                  height: 12px;
                  margin: 1px 8px 0 0;
                  cursor: pointer;
                }

                .img1 {
                  margin-left: 4px;
                }
              }

              p {
                font-size: 24px;
                margin-top: 11px;
                line-height: 24px;
                color: #ffffff;
              }

              .speed {
                width: max-content;
                margin-top: 12px;
                border-radius: 6px;
                background: rgba(201, 250, 91, 0.10);
                font-size: 13px;
                color: #C9FA5B;
                padding: 4px;
                line-height: 14px;

                img {
                  width: 14px;
                  height: 14px;
                  margin-right: 4px;
                }
              }
            }
          }

          .left-btnrevice {
            display: none;
          }
        }

        .top-info-right {
          flex: 0 0 173px;
          margin-top: 9px;

          .more {
            justify-content: flex-end;
            font-size: 16px;
            color: #c9fa5b;
            line-height: 16px;
            cursor: pointer;

            img {
              width: 5px;
              height: 8px;
              margin: 4px 0 0 8px;
            }
          }

          .profit-btn {
            margin-top: 22px;
            height: 48px;
            background: url("../../public/images/land/btnbg9.png") center no-repeat;
            background-size: 100% 100%;
            font-size: 16px;
            color: #000000;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }

      .h5top-name {
        display: flex;
        font-size: 24px;
        color: #ffffff;
        padding: 20px 0 0;
        justify-content: space-between;
        line-height: 24px;

        p {
          font-size: 18px;
          color: #a0a0a0;
          line-height: 16px;
          cursor: pointer;

          img {
            width: 12px;
            height: 12px;
            margin: 3px 0 0 6px;
            transform: rotate(180deg);
            transition: 0.5s;
          }

          .img1 {
            transform: rotate(0deg);
            transition: 0.5s;
          }
        }
      }

      .top-overview {
        margin-top: 24px;
        flex-wrap: wrap;
        padding: 32px;
        border-radius: 12px;
        background: #181818;

        .overview-info {
          flex: 0 0 14.2%;
          font-size: 16px;
          color: #ffffff;
          line-height: 16px;

          &:nth-child(-n + 14) {
            margin-bottom: 20px;
          }

          .info-name {
            margin-bottom: 8px;
            line-height: 12px;

            div {
              flex: 0 0 102px;
              font-size: 12px;
              color: #a8a8a8;
            }

            img {
              width: 12px;
              height: 12px;
              margin-left: 4px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .planet-title {
      margin: 40px 0 24px;
      font-size: 20px;
      color: #ffffff;
    }

    .planet-search {
      flex-wrap: wrap;

      .search-item {
        flex: 0 0 160px;
        margin-right: 20px;

        div {
          margin-bottom: 10px;
          font-size: 13px;
          color: #a8a8a8;
        }

        p {
          width: 100%;
          height: 40px;
          border: 1px solid rgba(255, 255, 255, 0.2);

          ::v-deep .el-select {
            width: 100%;
            height: 38px;
            color: #fff;

            .el-input {
              height: 38px;

              .el-input__wrapper {
                background-color: rgba(255, 255, 255, 0);
                box-shadow: none !important;
              }

              .el-input__inner {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .market-list {
      margin-top: 24px;
      flex-wrap: wrap;

      .palnet {
        position: relative;
        flex: 0 0 612px;
        height: 316px;
        margin-bottom: 14px;
        margin-right: 15px;
        background: url("../../public/images/land/bg1.png") no-repeat center;
        background-size: 108% auto;
        border-radius: 16px;
        border: 2px solid rgba(161, 246, 75, 0);
        box-sizing: border-box;
        cursor: pointer;

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:hover {
          border: 2px solid #d2fb71;

          &::after {
            content: "";
            position: absolute;
            top: 12px;
            left: 12px;
            width: 589px;
            height: 287px;
            background: url("../../public/images/new/markettag.png") center no-repeat;
            background-size: 100% 100%;
            z-index: 4;
          }

          .planet-con {
            .con-info {
              transition: 1s;
              margin-top: -78px;

              .info-btn {
                display: flex;
              }

            }
          }
        }

        .planet-con {
          position: relative;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 316px;
          overflow: hidden;
          z-index: 5;

          .sales-end {
            position: absolute;
            bottom: 32px;
            left: 32px;
            width: 98px;
            height: 80px;
            background: url("../../public/images/land/tagbg.png") center no-repeat;
            background-size: 100% 100%;
            font-size: 16px;
            color: #ffffff;
            z-index: 3;

            p {
              text-align: center;
              line-height: 80px;
              transform: rotate(-11deg);
            }
          }

          .bg1 {
            background: url("../../public/images/land/tagbg2.png") center no-repeat;
            background-size: 100% 100%;
          }

          .bg2 {
            background: url("../../public/images/land/tagbg1.png") center no-repeat;
            background-size: 100% 100%;
          }

          .planet-logo {
            flex: 0 0 200px;
            width: 200px;
            height: 200px;
            margin: 49px 24px 0 32px;
          }

          .con-info {
            flex: 1;
            padding-top: 20px;
            margin-right: 40px;
            transition: 1s;
            overflow: hidden;

            .tagrate {
              margin-top: 11px;
              padding: 4px 8px;
              justify-content: center;
              font-size: 12px;
              color: #5B88FA;
              border-radius: 4px;
              background: rgba(91, 136, 250, 0.20);
              line-height: 12px;

              img {
                width: 12px;
                height: 12px;
                margin: 0 0 0 4px;
              }
            }

            .sendtag1 {
              display: none;
            }

            .con-name {
              padding: 0 0 16px;
              font-size: 24px;
              color: #ffffff;
              line-height: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .con-level {
              flex-wrap: wrap;

              .level-name {
                padding: 0 6px;
                background: rgba(248, 164, 48, 0.2);
                height: 20px;
                font-size: 12px;
                color: #f8a430;
                text-align: center;
                line-height: 20px;
                border-radius: 4px;
              }

              .level-tag {
                padding: 0 6px;
                margin-left: 12px;
                background: rgba(201, 250, 91, 0.2);
                height: 20px;
                font-size: 12px;
                color: #c9fa5b;
                text-align: center;
                line-height: 20px;
                border-radius: 4px;
              }
            }

            .info-list {
              margin-top: 16px;
              padding: 20px 32px;
              background: linear-gradient(180deg,
                  rgba(161, 246, 75, 0.6) 0%,
                  rgba(50, 100, 20, 0.6) 97.92%);
              border-radius: 8px;
              flex-wrap: wrap;

              .list {
                flex: 0 0 50%;
                font-size: 13px;
                color: #c9fa5b;

                &:nth-last-child(-n + 2) {
                  margin-top: 18px;
                }

                p {
                  margin-top: 8px;
                  color: #fff;
                  font-size: 14px;

                  img {
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                    margin-top: 2px;
                    margin-left: 6px;
                  }
                }
              }
            }

            .info-btn {
              margin-top: 24px;
              display: none;

              div {
                flex: 0 0 98px;
                height: 40px;
                background: url("../../public/images/land/btnbg11.png") center no-repeat;
                background-size: 100% 100%;
                margin-right: 10px;
                font-size: 14px;
                color: #ffffff;
                cursor: pointer;
                line-height: 40px;
                text-align: center;

                &:last-child {
                  margin-right: 0;
                }

                &:first-child {
                  background: url("../../public/images/land/btnbg10.png") center no-repeat;
                  background-size: 100% 100%;
                  color: #000000;
                }
              }
            }
          }
        }
      }

      .nobtn {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 8;
        }

        &:hover {
          border: 2px solid rgba(161, 246, 75, 0);

          &::after {
            display: none;
          }

          .planet-con {
            .con-info {
              transition: 1s;
              margin-top: 0;

              .info-btn {
                display: none;
              }
            }
          }
        }
      }

      .levelbg2 {
        background-image: url("../../public/images/land/bg2.png");
        background-size: 108% auto;
      }

      .levelbg3 {
        background-image: url("../../public/images/land/bg3.png");
        background-size: 108% auto;
      }

      .levelbg4 {
        background-image: url("../../public/images/land/bg4.png");
        background-size: 108% auto;
      }

      .levelbg5 {
        background-image: url("../../public/images/land/bg5.png");
        background-size: 108% auto;
      }

      .levelbg6 {
        background-image: url("../../public/images/land/bg6.png");
        background-size: 108% auto;
      }

      .levelbg7 {
        background-image: url("../../public/images/land/bg7.png");
        background-size: 108% auto;
      }
    }

    .planet-page {
      padding: 0;

      ::v-deep .el-pagination {
        button {
          background-color: rgba(255, 255, 255, 0);
          color: #666666;
          font-size: 14px;
        }

        .el-pager {
          li {
            background: rgba(255, 255, 255, 0);
            font-size: 14px;
            color: #666666;
          }

          .is-active {
            background: #c9fa5b !important;
            border-radius: 4px !important;
            color: #000000 !important;
          }
        }
      }
    }
  }
}

.hideCon {
  height: 100vh;
  overflow: hidden;
}

.no-data {
  width: 100%;
  padding: 130px 0;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
  }

  span {
    display: block;
    margin: 0 auto;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
  }
}

.nullbtn {
  filter: grayscale(1);
}

.samllinfo-btn,
.h5level-name,
.h5top {
  display: none;
}

.modal-con {
  position: relative;
  padding: 40px;
  width: 520px;

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    padding-right: 40px;
    margin-bottom: 24px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: #fff;
  }

  .modal-info {
    margin-top: 24px;
    padding: 20px;
    border-radius: 8px;
    background: #242424;

    .modal-info-list {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;

        p {
          color: #c9fa5b;
        }
      }

      div {
        flex: 0 0 50%;

        color: rgba(255, 255, 255, 0.6);
      }

      p {
        flex: 0 0 50%;
        text-align: right;
        color: #ffffff;
      }
    }
  }

  .modal-addinfo {
    padding: 20px;
    border-radius: 8px;
    background: #242424;

    .modal-info-list {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        flex: 0 0 60%;

        color: rgba(255, 255, 255, 0.6);
      }

      p {
        flex: 0 0 40%;
        text-align: right;
        color: #ffffff;
      }
    }
  }

  .modal-tipsname {
    margin: 21px 0 11px;
    font-size: 14px;
    color: #fff;
    line-height: 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }

  .modal-tips {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 14px;

    div {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-btn {
    margin-top: 31px;
    justify-content: space-between;

    div {
      flex: 0 0 210px;
      height: 52px;
      background: url("../../public/images/land/btnbg12.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../../public/images/land/btnbg13.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }

  // 追加
  .modal-typename {
    font-size: 16px;
    color: #ffffff;
    margin: 24px 0 12px;
  }

  .modal-type-tab {
    justify-content: space-between;

    div {
      flex: 0 0 210px;
      height: 56px;
      background: url("../../public/images/land/textbtn3.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 15px;
      color: #ffffff;
      cursor: pointer;
    }

    .ontab {
      background: url("../../public/images/land/textbtn3_on.png") center no-repeat;
      background-size: 100% 100%;
      color: #000000;
      font-weight: 600;
    }
  }

  .modal-select {
    padding: 12px 16px;
    border: 1px solid #393939;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .modal-typetips {
    margin-top: 14px;
    font-size: 12px;
    color: #ffffff;

    span {
      color: #d2fb71;
    }
  }

  .modal-balance {
    padding: 12px 16px;
    border: 1px solid #393939;
    border-radius: 4px;
    box-sizing: border-box;

    .payamount {
      padding: 12px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      color: #fff;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      p {
        line-height: 20px;
      }

      div {
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
      }
    }
  }

  ::v-deep .el-dropdown {
    width: 100%;
    border: 0 !important;

    .el-dropdown-link {
      position: relative;
      width: 100%;
      border: 0 !important;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 11px;
        right: 0;
        width: 10px;
        height: 6px;
        background: url("../../public/images/land/downicon.png") center no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .modal-input {
    margin-bottom: 14px;
    padding: 12px 16px;
    border: 1px solid #393939;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    line-height: 16px;

    input {
      flex: 1;
      height: 16px;
      font-size: 16px;
      color: #fff;

      &:placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    p {
      color: #fff;
      padding: 0 16px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }

    div {
      padding-left: 16px;
      color: #c9fa5b;
      cursor: pointer;
    }
  }

  .payinfo {
    flex: 0 0 100%;
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    line-height: 28px;

    img {
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }
  }

  .paylist {
    margin-top: 24px;

    .list-info {
      justify-content: space-between;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);

      &:last-child {
        margin-top: 10px;
      }

      p {
        color: #fff;
      }
    }
  }
}

.payinfo1 {
  width: 406px;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;
  justify-content: space-between;

  img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }

  .inputname {
    justify-content: flex-end;
  }
}

.inputimg {
  margin-right: 8px;

  img {
    width: 28px;
    height: 28px;

    &:last-child {
      margin-left: -14px;
    }
  }
}

.inputname {
  flex: 1;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;

  span {
    padding: 0 4px;
  }
}

.modal-con1 {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .modal-input {
    margin: 20px 0 16px;
  }

  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }

  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }

  .forgottitle {
    margin-top: 24px;
    color: #c9fa5b;
    font-size: 16px;
    cursor: pointer;
    text-align: right;
  }

  .sucessicon {
    padding: 48px 0 20px;
    text-align: center;

    img {
      width: 64px;
      height: 64px;
    }
  }

  .success-title {
    text-align: center;
    font-size: 18px;
    color: #fff;
  }

  .success-tips {
    margin-top: 16px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}

.search-con {
  position: relative;
  padding: 20px 16px;
  background: #181818;

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 18px;
    height: 18px;
  }

  .search-title {
    padding-bottom: 20px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
  }

  .search-list {
    max-height: 400px;
    overflow-y: scroll;

    .list-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .list-name {
        margin-bottom: 12px;
        font-size: 15px;
        color: #ffffff;
        line-height: 16px;
      }

      .list-info {
        flex-wrap: wrap;

        div {
          flex: 0 0 32%;
          margin-right: 2%;
          margin-top: 10px;
          height: 40px;
          background: #242424;
          border-radius: 30px;
          font-size: 13px;
          color: #ffffff;

          &:nth-child(-n + 3) {
            margin-top: 0;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        .on {
          background: #c9fa5b;
          color: #000;
        }
      }
    }
  }

  .search-btn {
    margin-top: 20px;
    justify-content: space-between;

    div {
      flex: 0 0 48.5%;
      height: 52px;
      background: url("../../public/images/land/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: #ffffff;

      &:last-child {
        background: url("../../public/images/land/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}

.ratecon {
  width: 816px;

  .rate-list {
    font-size: 16px;
    color: #FFFFFF;

    div {
      margin-bottom: 24px;
    }
  }

  .rate-table {
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-sizing: border-box;

    .table-thead {
      background: rgba(201, 250, 91, 0.10);

      div {
        flex: 0 0 25%;
        height: 39px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        border-right: 1px solid rgba(255, 255, 255, 0.08);

        &:last-child {
          border-right: 0;
        }
      }
    }

    .tbody-info {
      border-top: 1px solid rgba(255, 255, 255, 0.08);

      div {
        flex: 0 0 25%;
        height: 39px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        border-right: 1px solid rgba(255, 255, 255, 0.08);
        line-height: 39px;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .ratebtn {
    width: 500px;
    height: 55px;
    margin: 24px auto 0;
    border-radius: 6px;
    background: #C9FA5B;
    font-size: 20px;
    color: #282626;
    font-weight: 600;
    cursor: pointer;
  }
}

.activity-wrap {
  margin-top: 48px;

  .wrap-item {
    flex: 1;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.20);

    &:nth-child(2) {
      margin-left: 20px;
    }

    &:nth-child(3) {
      margin-top: 16px;
    }

    .img1 {
      flex: 0 0 72px;
      width: 72px;
      height: 72px;
      margin-right: 24px;
    }

    .item-info {
      flex: 1;
      font-size: 20px;
      line-height: 24px;

      .info-name {

        color: #C9FA5B;

        img {
          width: 24px;
          height: 24px;
          margin-left: 12px;
        }
      }

      .info-desc {

        margin-top: 16px;
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .img2 {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin: 24px 0 0 24px;
      cursor: pointer;
    }
  }
}

@media (max-width: 1200px) {
  .h5-search {
    display: flex;
    margin-top: 20px;

    .user-title {
      font-size: 16px;
      color: #fff;
      line-height: 32px;
    }

    .user-land {
      flex: 1;
      justify-content: flex-end;

      img {
        width: 20px;
        height: 20px;
        margin: 6px 0 0 16px;
      }

      div {
        width: 96px;
        height: 32px;
        background: url("../../public/images/new/btnbg31.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        color: #000000;
        text-align: center;
        line-height: 32px;
      }
    }

    .search-icon {
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      margin-top: 10px;
    }
  }

  .planet {
    position: relative;
    padding: 68px 0 80px;

    .speedtag {
      top: inherit;
      bottom: 80px;
      height: 20px;
      min-width: 79px;

      div {
        padding-left: 4px;
        font-size: 10px;
        line-height: 18px;
      }

      img {
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
        margin: -1px 0 0 -3px;
      }
    }

    .planet-con {
      width: calc(100vw - 8px);

      .planet-top {
        padding: 0;
        border-radius: 0;
        background: none;

        .top-info {
          padding: 20px;
          border-radius: 12px;
          background: #181818;

          .top-info-left {
            .left-name {
              display: none;
            }

            .left-profit {
              flex-wrap: wrap;

              .profit-info {
                flex: 0 0 25%;
                margin-right: 0;

                &:nth-child(-n + 4) {
                  margin-bottom: 0;
                }

                .name {
                  font-size: 12px;
                }

                p {
                  font-size: 18px;
                  line-height: 18px;
                }
              }
            }

            .left-btnrevice {
              display: block;
              width: 311px;
              height: 40px;
              margin: 20px auto 0;
              background: url("../../public/images/new/btnbg30.png") center no-repeat;
              background-size: 100% 100%;
              text-align: center;
              line-height: 40px;
              font-size: 14px;
              color: #000;
              font-weight: 600;
            }
          }

          .top-info-right {
            display: none;
          }
        }

        .top-overview {
          margin-top: 12px;
          padding: 20px;
          border-radius: 12px;
          background: #181818;

          .overview-info {
            flex: 0 0 33.3%;
            margin-right: 0;

            &:nth-child(-n + 6) {
              margin-bottom: 16px;
            }

            &:nth-child(7) {
              margin-bottom: 0;
            }
          }
        }
      }

      .planet-title {
        margin: 20px 0 16px;
        font-size: 16px;
        line-height: 20px;
        display: none;
      }

      .planet-search {
        display: none;

        .search-item {
          margin-right: 2%;
          flex: 0 0 23.5%;

          &:last-child {
            margin-right: 0;
          }

          p {
            div {
              margin-bottom: 0;
            }
          }
        }
      }

      .market-list {
        .palnet {
          position: relative;
          flex: 0 0 100%;
          padding: 16px 16px 20px 12px;
          margin-right: 0;
          height: auto;
          background-size: 108% auto;

          &:hover {
            border: 0 solid #d2fb71;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 0;
              height: 0;
              background: none;
            }

            .planet-con {
              .con-info {
                transition: 1s;
                margin-top: 0;

                .info-btn {
                  display: none;
                }
              }
            }
          }

          .planet-con {
            position: relative;
            top: inherit;
            left: inherit;
            height: auto;

            .sales-end {
              top: 82px;
              left: 0;
              bottom: initial;
              width: 74px;
              height: 60px;

              p {
                line-height: 60px;
                font-size: 12px;
              }
            }

            .planet-logo {
              flex: 0 0 120px;
              width: 120px;
              height: 120px;
              margin: 40px 17px 0 0;
            }

            .con-info {
              padding-top: 0;
              margin-right: 0;

              .con-name {
                padding: 0 0 8px 0;
                font-size: 16px;
                white-space: normal;
              }

              .con-level {
                .level-name {
                  display: none;
                }

                .level-tag {
                  padding: 0;
                  margin-left: 0;
                  background: none;
                }
              }

              .info-list {
                margin-top: 20px;
                padding: 0;
                background: none;

                .list {
                  color: rgba(255, 255, 255, 0.6);

                  p {
                    margin-top: 6px;
                  }
                }
              }

              .info-btn {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .samllinfo-btn {
    margin-top: 20px;
    display: flex;

    div {
      flex: 0 0 98px;
      height: 40px;
      background: url("../../public/images/land/btnbg11.png") center no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
      line-height: 40px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        background: url("../../public/images/land/btnbg10.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }

  .h5level-name {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding: 0 6px;
    background: rgba(248, 164, 48, 0.2);
    height: 20px;
    font-size: 12px;
    color: #f8a430;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
  }

  .h5top {
    display: block;
    margin-bottom: 12px;

    .left-name {
      font-size: 16px;
      color: #ffffff;
      justify-content: space-between;
      line-height: 16px;

      div {
        &:last-child {
          font-size: 13px;
          color: #c9fa5c;
        }

        img {
          width: 5px;
          height: 8px;
          margin: 4px 0 0 4px;
        }
      }
    }

    p {
      margin-top: 8px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .activity-wrap {
    margin: 12px 0;
    width: 100%;
    justify-content: left;

    .wrap-item {
      flex: 1;
      padding: 12px;

      &:nth-child(2) {
        margin-left: 24px;
      }

      .img1 {
        flex: 0 0 48px;
        width: 48px;
        height: 48px;
        margin-right: 12px;
      }

      .item-info {
        font-size: 14px;
        line-height: normal;

        .info-name {
          img {
            width: 14px;
            height: 14px;
          }
        }

        .info-desc {
          margin-top: 4px;
        }
      }

      .img2 {
        margin: 12px 0 0 12px;
      }
    }
  }
}

@media (max-width: 767px) {
  .planet {
    padding: 56px 0 100px;

    .planet-con {
      .con-info {
        .teamtag {
          flex-wrap: wrap;
        }
        .sendtag1 {
          margin-top: 11px;
          display: flex !important;
          padding: 4px 8px;
          background: rgba(254, 180, 1, 0.08);
          border-radius: 4px;
          img {
            flex: 0 0 14px;
            width: 14px;
            height: 12px;
            margin-right: 4px;
          }

          div {
            font-size: 12px;
            line-height: 12px;
            color: #FEB401;
          }
        }
      }

      .planet-search {
        .search-item {
          margin-right: 2%;
          flex: 0 0 48%;

          &:nth-child(-n + 2) {
            margin-bottom: 10px;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }

          p {
            div {
              margin-bottom: 0;
            }
          }
        }
      }

      .planet-top {
        .top-info {
          .top-info-left {
            .left-profit {
              .profit-info {
                flex: 0 0 50%;
                margin-right: 0;
                margin-top: 20px;

                &:nth-child(-n + 2) {
                  margin-top: 0;
                }
              }
            }

            .left-btnrevice {
              width: 100%;
              height: 40px;
              margin: 20px auto 0;
            }
          }
        }

        .h5top-name {
          font-size: 16px;
          line-height: 16px;

          p {
            font-size: 14px;

            img {
              margin: 1px 0 0 6px;
            }
          }
        }

        .top-overview {
          margin-top: 12px;
          padding: 20px;
          border-radius: 12px;
          background: #181818;

          .overview-info {
            flex: 0 0 50%;
            margin-right: 0;

            p {
              padding-right: 5px;
            }

            &:nth-child(-n + 7) {
              margin-bottom: 16px;
            }
          }
        }
      }

      .market-list {
        .palnet {
          background-size: cover;

          .sendtag {
            display: none;
            top: 16px;
            right: 4px;

            img {
              display: none;
            }
          }

          .planet-con {
            .con-info {
              .con-name {
                padding-right: 40px;
              }
            }
          }

        }
      }
    }
  }

  .activity-wrap {
    overflow-y: scroll;

    .wrap-item {
      flex: 0 0 304px;

      &:nth-child(3) {
        margin-top: 0;
      }
    }
  }

  .samllinfo-btn {
    display: flex;

    div {
      flex: 0 0 32%;
    }
  }

  .payinfo1 {
    width: calc(100vw - 24px);
  }

  .modal-con {
    width: 100%;
    padding: 20px;

    .close {
      top: 20px;
      right: 20px;
    }

    .modal-title {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .modal-info {
      margin-top: 16px;
      padding: 12px;
    }

    .modal-typename {
      margin: 16px 0 12px;
      font-size: 14px;
      line-height: 14px;
    }

    .modal-type-tab {
      div {
        flex: 0 0 48%;
      }
    }

    .modal-input {
      padding: 12px 8px;
    }

    .modal-btn {
      div {
        flex: 0 0 48%;
        height: 48px;
      }
    }
  }

  .modal-con1 {
    width: 100%;
    padding: 20px;

    .close {
      top: 20px;
      right: 20px;
    }

    .modal-btn {
      div {
        flex: 0 0 48%;
        height: 48px;
      }
    }
  }
}</style>
